<template>
  <div class="tab-content">
    <div class="title-wrap">
      <h2 class="title">
        {{ cnt.title }}
      </h2>
      <!-- <div class="btnfr">
        <el-button class="sea-border" type="primary" icon="el-icon-search" plain @click="constDetail">选图网址</el-button>
      </div> -->
      <div class="fr" v-if="cnt.status">
        <el-button type="success" icon="el-icon-check" circle></el-button>Normal
      </div>
      <div class="fr1" v-else>
        <el-button type="danger" icon="el-icon-close" circle></el-button
        >Safeguard
      </div>
    </div>
    <div class="warning" v-if="cnt.tip != '' && cnt.tip != null">
      <p v-html="cnt.tip"></p>
    </div>
    <el-input
      type="textarea"
      v-model="cnt.label"
      @input="descInput"
      :placeholder="cnt.title + placeholder"
      class="isTextarea"
      :disabled="cnt.titleState == 'error'"
    ></el-input>
    <p class="error">{{ errorMsg }}</p>
    <el-button
      class="btn-border"
      type="primary"
      @click="search"
      plain
      icon="el-icon-search"
      :disabled="isSearching"
      >Search</el-button
    >
  </div>
</template>

<script>
import { message } from "@/utils/resetMessage";
export default {
  props: {
    // 带有默认值的对象
    tabContent: {
      type: Object,
      default() {
        return {
          title: "",
          subTitle: "",
          label: "",
        };
      },
    },
    isSearching: {
      type: Boolean,
    },
  },
  data() {
    return {
      placeholder:
        "Please copy and paste the Image ID number in this box and click Search,paste one ID number for each row, if there are multiple ID numbers, please press Enter to start a new line. If image is not loaded, it means that the picture does not exist or cannot be downloaded.",
      errorMsg: "",
      form: {
        imageId: "",
        webType: "",
      },
    };
  },
  computed: {
    cnt() {
      return this.tabContent;
    },
  },
  mounted() {
    this.cnt.label = "";
  },
  methods: {
    search() {
      if (this.cnt.label !== "" && this.cnt.label !== null) {
        if (this.cnt.label.split(/\n/).length > 500) {
          message.error({
            message: "The number of ID lines is limited to 500",
            duration: 4000,
            offset: 80,
          });
          return;
        }
        this.errorMsg = "";
        var recArray = this.cnt.label.split("\n");
        //搜索过滤回车
        var recNewArray = [];
        if (this.cnt.title == "Alamy" || this.cnt.title == "Envato Elements") {
          for (var i = 0; i < recArray.length; i++) {
            if (recArray[i].length > 3) {
              var rec = recArray[i].replace(/ /g, "");
              if (rec != null && rec != "" && typeof rec != "undefined") {
                if (recNewArray.indexOf(rec) < 0) {
                  recNewArray.push(rec);
                }
              }
            }
          }
        } else if (
          this.cnt.webType == "istockphoto" ||
          this.cnt.webType == "vistockphoto"
        ) {
          //istock的id单独处理
          for (var i = 0; i < recArray.length; i++) {
            var imageIDTemp = recArray[i];
            if (imageIDTemp.length > 3) {
              try {
                var rec = "";
                if (imageIDTemp.indexOf("-gm") > -1) {
                  //判断是否存-gm
                  rec = imageIDTemp.split("-gm")[1].split("-")[0];
                } else {
                  var pattern = /[0-9]\d{4,14}/g;
                  var matchResult = recArray[i]
                    .replace(/ /g, "")
                    .match(pattern);
                  if (matchResult.length > 0) {
                    matchResult.forEach((item) => {
                      rec += item;
                    });
                  }
                }
                if (rec != null && rec != "" && typeof rec != "undefined") {
                  if (recNewArray.indexOf(rec) < 0) {
                    recNewArray.push(rec);
                  }
                }
              } catch (e) {
                this.errorMsg = "存在ID无效";
              }
            }
          }
        } else {
          for (var i = 0; i < recArray.length; i++) {
            if (recArray[i].length > 3) {
              var pattern = /[0-9]\d{4,20}/g;
              var matchResult = recArray[i].replace(/ /g, "").match(pattern);
              try {
                if (matchResult.length > 0) {
                  var rec = "";
                  matchResult.forEach((item) => {
                    rec += item;
                  });
                  if (rec != null && rec != "" && typeof rec != "undefined") {
                    if (recNewArray.indexOf(rec) < 0) {
                      recNewArray.push(rec);
                    }
                  }
                }
              } catch (e) {
                this.errorMsg = "ID is ERROR";
              }
            }
          }
        }

        if (recNewArray.length > 0) {
          this.$emit("search", recNewArray);
        } else {
          this.errorMsg = "ID cannot be empty";
        }
      } else {
        this.errorMsg = "ID cannot be empty";
      }
    },
    //费用明细
    constDetail() {
      this.$emit("constDetail");
    },
    descInput() {
      this.txtVal = this.cnt.label;
      if (this.txtVal.split(/\n/).length > 500) {
        message.error({
          message: "The number of ID lines is limited to 500",
          duration: 4000,
          offset: 80,
        });
        return;
      }
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tab-content {
  .title-wrap {
    margin-bottom: 15px;
    display: flex;
    .title {
      display: inline-block;
      font-size: 32px;
      color: #000;
      font-weight: normal;
    }
    .btnfr {
      margin-left: 15px;
      font-size: 14px;
    }
    .fr {
      margin-left: auto;
      color: #44d995;
      margin-right: 30px;
      .iconfont {
        font-size: 20px;
      }
    }
    .fr1 {
      margin-left: auto;
      color: #ea0615;
      margin-right: 30px;
      .iconfont {
        font-size: 20px;
      }
    }
    .el-button.is-circle {
      margin-right: 10px;
      padding: 0;
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 50%;
    }
  }
  .btn-border.el-button {
    margin-bottom: 15px;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    color: #0773fc;
    background: #fff;
    border-color: #0773fc;
    outline: none;
    i {
      color: #0773fc;
    }
  }
  .warning {
    display: block;
    padding: 8px 16px;
    background-color: #fff6f7;
    border-radius: 4px;
    border-left: 5px solid #fe6c6f;
    margin: 20px 0;
    p {
      font-size: 14px;
      color: #5e6d82;
      line-height: 1.5em;
    }
  }
  .el-button--primary.is-plain:hover {
    background: #0773fc;
    border-color: #0773fc;
    color: #ffffff;
  }
  .error {
    margin-bottom: 10px;
    color: #f56c6c;
  }
}
</style>
