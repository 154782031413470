<template>
  <el-dialog
    title="Account Balance"
    :visible.sync="visible"
    @close="closeDialog"
    class="table"
  >
    <el-row>
      <el-button type="text" class="font-info" icon="el-icon-user"
        >Membership level:</el-button
      >
      <i class="level"> {{ userLevelId }}</i>
    </el-row>
    <el-row>
      <el-button type="text" class="font-info" icon="el-icon-coin"
        >Balance:</el-button
      >
      <i class="balance"> {{ balance }} </i> B
    </el-row>
  </el-dialog>
</template>

<script>
import { getSessionUser } from "../../../api/auth";
export default {
  props: ["dialogVisible"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val) this.getUserList();
      },
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      balance: 0,
      userLevelId: "-",
    };
  },
  methods: {
    getUserList() {
      getSessionUser({})
        .then((result) => {
          if (result != null) {
            this.balance = result.blance;
            //TODO 判断，对应的会员等级
            if (result.userLevelId != null) {
              //是否已是vip
              //vip未过期
              if (new Date() <= result.vipEndTime) {
                if (result.userLevelId == 2) {
                  this.userLevelId = "Standard VIP";
                } else if (result.userLevelId == 4) {
                  this.userLevelId = "Senior VIP";
                } else if (result.userLevelId == 5) {
                  this.userLevelId = "Enterprise VIP";
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    linkOverseas(row) {
      window.open(row.enUrl);
    },
    linkDomestic(row) {
      window.open(row.zhUrl);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-row {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.font-info {
  color: #3e3e3e;
}
.font-warning {
  color: #e6a23c;
}
.level {
  font-size: 12px;
  color: #e6a23c;
  margin-top: 6px;
  font-size: 24px;
}
.balance {
  font-size: 12px;
  color: #409eff;
  margin-top: 6px;
  font-size: 24px;
}
</style>
